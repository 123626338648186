import {Tetris} from "./model.js";
import {TetrisView} from "./view.js";
import {
	STARTING_DRAW_INTERVAL, 
	X_SWIPE_THRESHOLD, 
	Y_SWIPE_THRESHOLD,
	TOUCH_EVENT_DELAY,
} from "./const.js";

const canvas = document.getElementById("gamecanvas");
const scoreboard = {
	level: document.getElementById("level"),
	score: document.getElementById("score"),
};
const tetris = new Tetris();
const view = new TetrisView(tetris, canvas, scoreboard);
let ts;
let lastTouchEv = Date.now();

function thaLoop() {
	tetris.update();
	view.render();
	if (tetris.gameOver) {
		alert("GAME OVER");
		return;

	}
	setTimeout(thaLoop, Math.max(STARTING_DRAW_INTERVAL-tetris.getLevel()*50, 100));
}

function keyHandler(ev) {
	let code = ev.keyCode;
	switch (code) {
	case 39:
	case 68:
		tetris.right();
		break;
	case 37:
	case 65:
		tetris.left();
		break;
	case 38:
	case 87:
		tetris.rot();
		break;
	case 40:
	case 83:
		tetris.down();
		break;
	case 67:
		tetris.hold();
		break;
	}
	view.render();
}

function touchStartHandler(ev) {
	ts = {
		x: ev.touches[0].clientX,
		y: ev.touches[0].clientY,
	};
}

function touchMoveHandler(ev) {
	let tm = {
		x: ev.touches[0].clientX,
		y: ev.touches[0].clientY,
	};
	if ((Date.now() - lastTouchEv) < TOUCH_EVENT_DELAY) return;
	lastTouchEv = Date.now();

	if (ts.x > tm.x+X_SWIPE_THRESHOLD) {
		tetris.left();
		view.render();
		return;
	} else if (ts.x < tm.x-X_SWIPE_THRESHOLD) {
		tetris.right();
		view.render();
		return;
	}

	if (ts.y < tm.y-Y_SWIPE_THRESHOLD){
		tetris.down();
		view.render();
	}
}

function touchEndHandler(ev) {
	ev.preventDefault();

	let te = {
		x: ev.changedTouches[0].clientX,
		y: ev.changedTouches[0].clientY,
	};

	if (ts.y > te.y + 50) {
		tetris.hold();
		view.render();
		return;
	}

	if (Math.abs(te.x-ts.x) > Y_SWIPE_THRESHOLD || Math.abs(te.y-ts.y) > X_SWIPE_THRESHOLD) return;

	tetris.rot();
	view.render();
}

window.onload = () =>{
	tetris.start(); 
	thaLoop();
};
document.onkeydown = keyHandler;
document.ontouchstart = touchStartHandler;
document.ontouchmove = touchMoveHandler;
document.ontouchend = touchEndHandler;
