import { WIDTH, SHAPES } from "./const.js";

class Piece {
	constructor() {
		this.x = Math.floor(WIDTH/2);
		this.y = 0;
		this.shape = SHAPES[Math.floor(SHAPES.length * Math.random())];
	}
	
	rot(){
		let rows = this.shape.length;
		let cols = this.shape[0].length;

		let newShape = Array(cols);
		for (let i=0; i<cols; i++) {
			newShape[i] = Array(rows).fill(0);
		}

		for (let i=0; i<rows; i++) {
			for (let j=0; j<cols; j++) {
				newShape[j][rows-i-1] = this.shape[i][j];
			}
		}
		this.shape=newShape;
	}
}

export { Piece };

