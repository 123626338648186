import { COLORS, BLOCK_SIZE } from "./const.js";

class TetrisView {
	constructor(game, canvas, scoreboard) {
		this.game = game;
		this.ctx = canvas.getContext("2d");
		this.ctx.scale(BLOCK_SIZE, BLOCK_SIZE);
		this.scoreboard = scoreboard;
	}

	rect(item, x, y) {
		this.ctx.lineWidth=0.05;
		this.ctx.beginPath();
		this.ctx.fillStyle = COLORS[item];
		this.ctx.roundRect(x, y, 1, 1, 0.1); 
		this.ctx.stroke();
		this.ctx.fill();
	}

	render() {
		let grid = this.game.grid;


		grid.forEach((row, i) => {
			row.forEach((item, j) => {
				this.rect(item, j, i);
			});
		});
		this.updateScoreBoard();
		this.renderPiece();
	}

	updateScoreBoard() {
		this.scoreboard.level.innerHTML = this.game.getLevel();
		this.scoreboard.score.innerHTML = this.game.score;
	}

	renderPiece() {
		let piece = this.game.current;
		if (!piece) return;

		piece.shape.forEach((row, i) => {
			row.forEach((item, j) => {
				if (item>0) {
					this.rect(item, j+piece.x, i+piece.y); 
				}
			});
		}); 

	}
}


export {TetrisView};
