import { WIDTH, HEIGHT } from "./const.js";
import { Piece } from "./block.js";


class Tetris {
	constructor() {
		this.held;
		this.score;
		this.lines = 0;
		this.gameOver = false;
		this.merge = false;
		this.grid = Array(HEIGHT);
		for (let i=0; i<HEIGHT; i++) {
			this.grid[i] = Array(WIDTH).fill(0);
		}
	}
	hold() {
		let tmp = this.held;
		this.held = this.current;
		this.held.y = 0;
		this.held.x = Math.floor(WIDTH/2);

		if (!tmp) {
			this.current = new Piece();
		} else {
			this.current = tmp;
		}
	}
	start() {
		let  p = new Piece();
		this.current = p;
		this.score = 0;
	}
	getLevel() {
		return Math.floor(this.lines / 10)+1;
	}
	updateScore(rows) {
		this.lines += rows;

		let scoreToAdd = 0;
		switch (rows) {
		case 1:
			scoreToAdd=40;
			break;
		case 2:
			scoreToAdd=100;
			break;
		case 3:
			scoreToAdd=300;
			break;
		case 4:
			scoreToAdd=1200;
			break;
		}

		this.score += (scoreToAdd*this.getLevel());

	}
	collision() {
		const hitFloor = this.current.shape.length+this.current.y >= HEIGHT;
		if (hitFloor) return true;

		let shape = this.current.shape;
		let x = this.current.x;
		let y = this.current.y;

		for (let i = 0; i<shape.length; i++) {
			for (let j = 0; j<shape[i].length; j++) {
				if (!shape[i][j]) continue; 
				
				if (this.grid[i+y+1][j+x] != 0){
					return true;
				}
			}
		}
		return false;
	}

	sideCollision(direction) {
		let shape = this.current.shape;
		let x  = this.current.x;
		let y = this.current.y;

		if (direction == -1) {
			if (x < 1) return true;
            
			for (let row = 0; row<shape.length; row++) {
				if(!shape[row][0]) continue;
				if (this.grid[row+y][x-1] > 0) return true;
			}

		} else {
			if (x+shape[0].length > WIDTH-1) return true;
			for (let col = 0; col<shape[0].length; col++) {
				for (let row = 0; row<shape.length; row++) {
					if(!shape[row][col]) continue;
					if(this.grid[row+y][x+col+1] > 0) return true;
				}
			}
		}
	}
	clearRows(rows) {
		for (let row of rows) {
			let newGrid = this.grid.slice(0, row).concat(this.grid.slice(row+1));
			newGrid = [Array(WIDTH).fill(0)].concat(newGrid);
			this.grid = newGrid;
		}
	}
	checkRows(affected) {
		let rowsToclear = [];

		for (let row of affected) {
			let completeRow = true; 
			for (let col=0; col<WIDTH; col++) {
				if (!this.grid[row][col]) {
					completeRow = false;
					break;
				}
			}
            
			if (completeRow) rowsToclear.push(row);
		}
		if (rowsToclear.length) {
			this.updateScore(rowsToclear.length);
			this.clearRows(rowsToclear);
		}
	}
	update() {
		if (this.current == null) {
			this.createPiece();
		}
		if (this.collision() && !this.merge) {
			if (this.current.y == 0) {
				this.gameOver = true;
			}

			this.merge = true;
			return;
		}
        
		if (this.merge) {
			if (!this.collision()) {
				this.merge = false;
				return;
			}
			let affectedRows = new Set();
			this.current.shape.forEach((row, i) => {
				row.forEach((item, j) => {
					if (item > 0) {
						this.grid[i+this.current.y][j+this.current.x] = item;
						affectedRows.add(i+this.current.y);
					}
				});
			});

			this.checkRows(affectedRows);
			this.current = new Piece();
			this.merge = false;
			return;
		}

		this.current.y += 1;
		this.score++;
	}

	move(direction) {
		if (this.sideCollision(direction)) return;
		this.current.x += (1*direction);
	}

	right() {
		this.move(1);
	}

	left() {
		this.move(-1);
	}

	down() {
		this.update();
	}

	rot() {
		this.current.rot();
	}

}

export { Tetris };
