const WIDTH = 10;
const HEIGHT = 22;
const BLOCK_SIZE =25;
const STARTING_DRAW_INTERVAL = 550;
const X_SWIPE_THRESHOLD = 15;
const Y_SWIPE_THRESHOLD = 15;
const TOUCH_EVENT_DELAY = 75;


const COLORS = [
	"rgb(13, 32, 95)", //white,
	"rgb(85, 170, 240)" , // "blue",    
	"rgb(123, 223, 123)", // "green",   
	"rgb(218, 82, 96)"  , // "red",     
	"rgb(244, 225, 96)" , // "yellow",  
	"rgb(240, 185, 85)" , // "orange",  
	"rgb(110, 210, 105)" , // "orange",
	"rgb(163, 93, 211)", //purple
];

const SHAPES = [
	[
		[1],
		[1],
		[1],
		[1],
	],
	[
		[4, 4],
		[4, 4],
	],
	[
		[5, 0],
		[5, 0],
		[5, 5],
	],
	[
		[0, 1],
		[0, 1],
		[1, 1],
	],
	[
		[3, 3, 0],
		[0, 3, 3],
	],
	[
		[0, 6, 6],
		[6, 6, 0],
	],
	[
		[7, 7, 7],
		[0, 7, 0],
	],
];

export {
	WIDTH,
	HEIGHT,
	BLOCK_SIZE,
	COLORS,
	SHAPES,
	STARTING_DRAW_INTERVAL,
	X_SWIPE_THRESHOLD,
	Y_SWIPE_THRESHOLD,
	TOUCH_EVENT_DELAY,
};
